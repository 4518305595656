<template>
  <div class="page">
        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center"><span style="font-weight:bold">End User License
                Agreement</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">TERMS OF SERVICE</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Effective 01 January 2024</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Welcome to use the services of {{ compony }} Limited
                (“{{ compony }}”the “Company” or “We”), including but not limited to Yuliverse(“Services”). These
                Terms of Service (“Terms”) are applicable to the utilization of all services rendered by us, including
                games developed and/or published by {{ compony }}, web pages in relation to {{ compony }}
                and the aforementioned games, as well as other services such as applications or any social functions
                connected to the aforementioned services, or any other services to which these Terms are attached
                (“Services”). These Terms govern the relationship between you and us with respect to your useage of the
                Services. By downloading, installing, accessing or using any part of the Services you signify your
                agreement to these Terms and consent to be bound by them. The Usage of the Services is also governed by
                our Privacy Policy and other relevant policies, which are incorporated herein by
                reference.</span><span>&#xa0;</span><span style="font-weight:bold">These Terms along with our Privacy
                Policy affect your legal rights and obligations. If you do not accept to these Terms, you may not access
                or use the Services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Prior to accessing or using the Services, you must consent to
                these Terms of Service, our Privacy Policy, and other related policies. By using the Services, you
                affirm that you are at least 18 years of age or older. If you fall within the age range of 13 to 17, or
                if you are considered a minor according to the applicable laws in your jurisdiction, you declare that
                your legal guardian or legal representative has thoroughly reviewed and agreed to these Terms, granting
                you permission to access and use the Services.BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICES,
                YOU CONFIRM YOUR ACCEPTANCE OF THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE,
                PLEASE REFRAIN FROM INSTALLING, USING, OR OTHERWISE ACCESSING THE SERVICES. USE OF THE SERVICES IS
                PROHIBITED WHERE CONTRARY TO LAW.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We retain the right to modify these terms at any time. Your
                continued use of the Services will be deemed as confirmation of your acceptance of the revised terms. In
                addition to these Terms, you also agree to adhere to any supplementary policies of the Services,
                including policies relating to specific services (such as forums, chats, contests, or sweepstakes), as
                well as all other relevant operational rules or policies, each of which is incorporated by reference
                when applicable.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Please be aware that when accessing the Services via a mobile
                network, your network or roaming provider's charges for messaging, data usage, and other applicable fees
                will be applicable. It is possible that downloading, installing, or using the Services may be prohibited
                or restricted by your network provider, and some certain portions or all of the Services may not work
                with your network provider or device.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">1. License</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Subject to you agreeing to these Terms and your continued
                compliance with these Terms and any other relevant policies, we grant you a personal, non-exclusive,
                non-transferable, non-sublicensable, revocable and limited license to access and use the Services for
                your own non-commercial entertainment purposes. You hereby undertake not to use the Services for any
                other purpose other than personal and non-commercial use.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The provision of the Services is solely intended for your
                personal enjoyment and shall not be exploited for financial gain. It is strictly prohibited to sell,
                rent or transfer your access to the Services to any third party. You shall refrain from creating
                Services accounts using false identities or misleading information, or on behalf of individuals other
                than yourself. You are prohibited from accessing or using the Services if you have previously been
                suspended or banned by us from any aspect of the Services, or if you have been prohibited from playing
                any game developed by {{ compony }}. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You may be required or given the option to create a user
                account and select a username for your use in the Services. We reserve the right to remove or reclaim
                any usernames at our discretion and without prior notice, including but not limited to instances where
                usernames are deemed offensive or subject to a third party's claim that the username infringes upon
                their rights.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>By default, the Services support the use of only one player
                account per game on a supported device. Additionally, the Services may not support or facilitate the use
                of a single account across multiple devices.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">a. Restrictions on License </span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Your usage of the Services is expressly strictly tied to your
                compliance with these Terms. Any use of the Services in violation with these Terms is strictly
                prohibited and can result in the immediate revocation of your limited license. Such non-compliant use
                may also subject you to liability towards {{ compony }} or for violations of law.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You agree that, under no circumstances, will you:</span></p>
        <ul type="disc" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">use the Services for fraudulent or abusive purposes
                    (including, but not limited to, by using the Services to impersonate any person or entity, or
                    otherwise misrepresent our affiliation with any person, entity or the Services);</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">interfere with or disrupt the Services, servers or
                    networks that facilitate the provision of the Services;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">attempt to decompile, reverse engineer, disassemble or
                    hack any part of the Services, or endeavor to bypass or undermine any of the encryption
                    technologies, security measures, or data transmitted, processed or stored by us;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">post or publish any information that is abusive,
                    threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or objectionable or
                    offensive or engage in ongoing toxic behavior, such as by repeatedly posting unsolicited
                    information;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">share any information that contains nudity, excessive
                    violence, offensive subject matter or that links to such content;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">engage in or advocate for the harassment, abuse, or harm
                    of another person or group, including, but not limited to, {{ compony }} employees and our
                    customer service representatives;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">solicit or attempt to solicit login information or any
                    other login credentials or personal information from other users of the Services;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">‘harvest’, ‘scrape’, collect or post any information
                    about other individuals who use the Services, including, but not limited to, personal data or
                    information, through means such as "pixel tags," cookies, GIFs, or similar passive collection
                    mechanisms;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">disrupt the normal flow of a game or engage in conduct
                    likely to detrimentally affect other players' ability to compete fairly;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">initiate, assist, or participate in any form of attack,
                    including without limitation distribution of a viruses or denial of service attacks against the
                    Services, or any other endeavors aims at disrupt the Services or any other person’s use or enjoyment
                    of the Services;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">disobey any requirements or regulations of any network
                    connected to the Services;</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">circumvent technological measures implemented to control
                    access to or aspects of the Services; or</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">engage in any conduct that we determine to be contrary to
                    the spirit of fair play or the intended use of the Services.</span></li>
        </ul>
        <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You acknowledge and agree that you are solely responsible for
                your actions in the Services and any actions conducted through your player account or device.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>{{ compony }} reserves the sole discretion to
                determine the conduct it deems to be in breach of these Terms or other rules of the Services, or
                contrary to the intent, essence or spirit of these Terms of Service or the Services themselves. As a
                result, we reserve the right to take action, which may include complete or partial termination of your
                access to the Services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">2. Suspension and Termination of
                Account and Service</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>WITHOUT LIMITING ANY OTHER REMEDIES, IF WE HAVE REASON TO
                BELIEVE THAT YOU HAVE FAILED TO COMPLY WITH ANY OF THESE TERMS OR ENGAGED IN ACTUAL OR SUSPECTED ILLEGAL
                OR IMPROPER USE OF THE SERVICES, WE MAY, WITH OR WITHOUT PRIOR NOTICE, IMPOSE LIMITATIONS, SUSPEND,
                TERMINATE, MODIFY, OR DELETE PLAYER ACCOUNTS OR ACCESS TO THE SERVICES, OR SPECIFIC PORTIONS THEREOF.
                PLEASE NOTE THAT ACCOUNT TERMINATION OR LIMITATION MAY RESULT IN THE LOSS OF YOUR USERNAME, PERSONA
                WITHIN THE SERVICES, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED ITEMS, AND PURCHASED ITEMS ASSOCIATED
                WITH YOUR USE OF THE SERVICES. WE ARE UNDER NO OBLIGATION TO PROVIDE COMPENSATION FOR ANY LOSSES OR
                CONSEQUENCES INCURRED AS A RESULT THEREOF.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>IN ADDITION TO ANY OTHER REMEDIES AVAILABLE TO US, IF
                {{ compony }} BELIEVES THAT USERS ARE CREATING POTENTIAL RISKS OR LEGAL LIABILITIES, INFRINGING
                UPON THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING IN A MANNER INCONSISTENT WITH THE
                LETTER OR SPIRIT OF THESE TERMS OR OTHER POLICIES, WE MAY, AT OUR DISCRETION, LIMIT, SUSPEND, OR
                TERMINATE THE SERVICES OR SPECIFIC PORTIONS THEREOF. THIS MAY INCLUDE PROHIBITING ACCESS TO THE
                SERVICES' GAMES, SITES, CONTENT, SERVICES, AND TOOLS, DELAYING OR REMOVING HOSTED CONTENT, AND TAKING
                TECHNICAL AND LEGAL MEASURES TO PREVENT USERS FROM ACCESSING THE SERVICES. ADDITIONALLY, IN APPROPRIATE
                CIRCUMSTANCES AND AT OUR SOLE DISCRETION, WE MAY SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO ARE BELIEVED
                TO BE REPEAT INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We reserve the right to discontinue the offering and/or
                supporting the Services , a specific game or any part of the Services at any time. In such cases, your
                license to use the Services or any affected portion will be automatically terminated. Please note that
                we are not obligated to provide refunds, benefits, or compensation to users in connection with the
                discontinuation of such Services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Termination of your account may involve the disabling of your
                access to the Services or any relevant parts, including any content that you or others have
                submitted.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You have the option to terminate your account at any time and
                for any reason by contacting our customer support at support@yuliverse.com or by using the in-game "[Log
                off]" function available in certain games.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">3. Third-Party Services and
                Material</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The Services may include links to websites or services
                operated by third parties or feature content provided by third-party services that allow you to access
                and use certain third-party services, such as video sharing platforms. Your use of any third-party
                websites, services, and/or content within third-party services is subject to the terms and conditions
                set forth by the relevant third-party service provider, as well as any applicable licenses governing the
                use of such content. Please note that these Terms remain in effect alongside the terms and conditions of
                the third-party service provider.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We assume no responsibility for any third-party services
                and/or content within third-party services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>These Terms shall be in addition to, and take precedence over
                (where not prohibited), any terms and conditions applicable to the distribution platform (such as Apple
                App Store, Google Play, or Microsoft Store) through which you acquire the Services. Any agreements made
                between you and the relevant third-party entity regarding the distribution platform shall be considered
                separate and subordinate to these Terms.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">4. Ownership</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">a. Intellectual Property
                Rights</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>All rights, title and interest in and to the Services
                including but not limited to games, titles, computer code, themes, objects, characters, names, dialogue,
                catchphrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, moral
                rights, documentation, in-game chat transcripts, recording of games played using our game clients and
                our game clients and server software)(“{{ compony }} Property”), are either owned by</span><span
                style="-aw-import:spaces">&#xa0; </span><span>us or licensed to us, and are protected by copyright and
                other intellectual property rights under the laws of Hong Kong SAR , other jurisdictions and
                international conventions. We reserve all rights, including but not limited to intellectual property
                rights or other proprietary rights, in connection with our games and the Service. Unless expressly
                provided in the Terms, we do not grant any express or implied rights to the {{ compony }}
                Property.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">b. Accounts</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>YOU ACKNOWLEDGE AND AGREE THAT YOU DO NOT PROCESS ANY
                OWNERSHIP OR OTHER PROPERTY INTEREST IN ANY SERVICES ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT
                ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL ALWAYS</span><span style="-aw-import:spaces">&#xa0;
            </span><span>BE OWNED BY US AND SHALL BE FOR OUR EXCLUSIVE.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">c. Virtual Items</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We own, hav</span><span
                style="text-decoration:underline">e</span><span> obtained licensed for , or otherwise hold rights to use
                all the content that appears in the Services. Notwithstanding any provision to the contrary herein, you
                expressly agree that you have no right or title to any content that appears in the Services, including
                without limitation the virtual goods or currency that may appear or originate within any part of the
                Services, whether acquired through game play or purchased, as well as any other attributes associated
                with an account or stored on the Services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">5. User Content</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>“User Content” refers to any communications, images, sounds,
                and all the material, data, and information that you upload or transmit to the Services, or that other
                users upload or transmit to the Services or that other users upload or transmit , including without
                limitation chat text. By transmitting or submitting any User Content while using the Services, you
                affirm, represent and warrant that such transmission or submission: (a) is accurate, non-confidential
                and not misleading; (b) does not violate any laws, contractual restrictions, or third party rights, and
                that you have obtained permission from any third party whose personal information or intellectual
                property is included in the User Content; (c) is free from viruses, adware, spyware, worms or any other
                malicious code; and (d) you acknowledge and agree that any personal information within such content will
                always be processed by us in accordance with our Privacy Policy.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We assume no responsibility for the conduct of any user
                submitting any User Content, and take no responsibility for monitoring the Services for inappropriate
                content or conduct. We do not have the ability to pre-screen or monitor all User Content. Your use of
                the Services is at your own risk. By using the Services, you may come across User Content that is
                offensive, indecent or does not meet your expectations. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You assume all risks associated with the use of any User
                Content available in connection with the Services. At our discretion, our representatives or technology
                may monitor and/or record your interaction with the Services or communications, including chat text,
                when you are using the Service.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>By agreeing to these Terms, you provide your irrevocable
                consent to such monitoring and recording. You acknowledge and agree that you have no expectation of
                privacy regarding the transmission of any User Content, including chat text or voice
                communications.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We reserve the sole discretion to review, monitor, prohibit,
                edit, delete, disable access to or otherwise make unavailable any User Content ,including without
                limitation your User Content, without notice for any reason or for no reason at any time. If we choose,
                at any time and at our sole discretion, to monitor the Services, we nonetheless assume no responsibility
                for User Content and have no obligation to modify or remove any inappropriate User Content. We retain
                the right, but not the obligation, at our sole discretion, to edit, refuse to post, or remove any User
                Content.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">a. Public Discourse</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The Services may include various forums, blogs and chat
                features that allow to post User Content, including your observations and comments on designated topics.
                However, we cannot guarantee that other members will not use the ideas and information you share.
                Therefore, if you have confidential information or ideas that you wish to keep private or prevent others
                from using, it is advisable not to post them on the Services. We shall have no responsibility to assess,
                utilize, or compensate you for any ideas or information you choose to submit.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">b. Responsible for Your Own
                Content</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You are solely responsible for the information you post on,
                through or in connection with the Services, as well as the information you provide to
                others.</span><span>&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">c. Your License to CLOUDMATRIX
                HOLDINGS</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You hereby grant to us an unrestricted, irrevocable,
                perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to
                sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create
                derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense,
                commercially exploit, transfer, lease, transmit, publicly display, publicly perform, or provide access
                to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter
                into computer memory, and use and practice, in any way, your User Content as well as all modified and
                derivative works thereof in connection with our provision of the Services, including marketing and
                promotions of the Services. You also hereby grant to us the right to authorize others to exercise any of
                the rights granted to us under these Terms. You further hereby grant to us the unconditional,
                irrevocable right to use and exploit your name, likeness and any other information or material included
                in any User Content and in connection with any User Content, without any obligation to you. Except as
                prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User
                Content, regardless of whether your User Content is altered or changed in any manner. We do not claim
                any ownership rights in your User Content and nothing in these Terms is intended to restrict any rights
                that you may have to use and exploit your User Content. We have no obligation to monitor or enforce your
                intellectual property rights to your User Content.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">d. User Interactions</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You are fully responsible for your interactions with other
                users of the Services and any other parties with whom you engage through the Services. While we reserve
                the right, we are not obligated to intervene in ay manner in such disputes. You are required to
                cooperate fully with us in the investigation of any suspected unlawful, fraudulent, or improper
                activities.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>In the event that you have a dispute with one or more users,
                you hereby release {{ compony }} (including its officers, directors, agents, affiliates, parent
                companies, subsidiaries, joint ventures, and employees) from all claims, demands, and damages (whether
                actual or consequential) of every kind and nature, known and unknown, arising out of or in any way
                connected with such disputes.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">e. Social Media and Game
                Content</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Subject to the limitations stated in these Terms, you have
                the right to upload captions (such as audio, video and screenshots) of the Services to social media
                services (such as Facebook), online video services (such as YouTube), online picture sharing services
                (such as Instagram).However, all such material must be uploaded without any edits that would</span><span
                style="-aw-import:spaces">&#xa0; </span><span>misrepresent the Services or any games included in the
                Services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">6. Fees and Purchase Terms</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">a. Purchases</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Withinn the Services, you may have the opportunity to
                purchase, with “real world” money, a limited, personal, non-transferable, non-sublicensable, revocable
                license to use “virtual currency”, including but not limited to virtual cash or gems solely for use in
                the Services; Additionally, you may acquire the right to use “virtual in-game items” (collectively
                referred to as “virtual currency” and/or “Virtual Items”) in the Services, as well as gain access to
                additional functionalities through paid subscription. You are exclusively permitted to purchase Virtual
                Items and subscriptions from us or our authorized partners through the Services, and not through any
                other means.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We have the right to manage, regulate, control, modify or
                eliminate Virtual Items and the functionalities accessed with paid subscriptions at any time, with or
                without prior notice. In the event that we exercise any of these rights, we shall have no liability to
                you or any third party. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Explicitly, the transfer of Virtual Items is prohibited. You
                shall not sell, purchase, redeem or</span><span style="-aw-import:spaces">&#xa0; </span><span>transfer
                Virtual Items to any individual, entity or including but not limited to us, another user or any third
                party, or engaging in any similar activities.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS AND
                SUBSCRIPTIONS MADE THROUGH THE SERVICE ARE DEEMED FINAL AND NON-REFUNDABLE.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The provision of Virtual Items or paid subscriptions for use
                within the Services is a service provided by us, and it commences immediately upon {{ compony }}
                acceptance of your purchase.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">b. Payment of Fees</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You hereby agree to fulfill all fees and applicable taxes
                incurred by yourself or any individual using an account registered under your name. We reserve the right
                to modify the pricing for the goods and services offered through the Services at any time. PLEASE BE
                AWARE THAT {{ compony }} IS UNDER NO OBLIGATION TO ISSUE REFUNDS FOR ANY REASON. YOU ACKNOWLEDGE
                THAT UPONE CLOSURE ON AN ACCOUNT, WHETHER VOLUNTARY OR INVOLUNTARY, YOU SHALL NOT RECEIVE MONETARY
                COMPENSATION OR ANY FORM OF RECOMPENSE FOR UNUSED VIRTUAL ITEMS. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">7. Updates to the Service</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You understand that the Services are constantly changing and
                evolving. It may be necessary for you to accept updates to the Services, including the installation of
                updates to game clients on your device. You acknowledge and consent that we have the authority to update
                the Services, with or without prior notification. At times, you may need to update third-party software
                or the hardware of your device in order to access and utilize the Services.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">8. Beta tests</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We may extend to you the opportunity to participate in beta
                tests, whether open or closed, for specific components of the Services. Your participation in such beta
                tests may be subject to a separate non-disclosure agreement and/or supplemental policies.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>By applying to and/or participating in beta tests you
                acknowledge and accept the following:</span></p>
        <ul type="disc" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">Due to the incomplete and experimental nature of the
                    beta-tested parts of the Services, we may not provide any customer service or assistance regarding
                    such parts of the Services. It is possible that the beta-tested parts may experience functionality
                    issues on certain devices, regardless of their listed hardware or software requirements or
                    specifications.</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">Any in-app purchases, items, equipment, experience
                    points, in-game currency and other related content may be periodically reset by us during the course
                    of the beta tests. We shall not refund for any lost content of such nature.</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">Any feedback or ideas submitted by you to us regarding
                    the beta-tested parts of the Services may be utilized by us without any obligation to provide
                    compensation to you. By submitting or creating any material, content (such as in-game user-created
                    content) or feedback concerning the beta-tested parts of the Services, you grant us an unlimited,
                    sublicensable, fully paid-up, nonexclusive and transferable license to utilize such material,
                    content and/or feedback for our business purposes.</span></li>
            <li
                style="margin-left:26.87pt; padding-left:9.13pt; font-family:serif; font-size:10pt; -aw-font-family:'Symbol'; -aw-font-weight:normal; -aw-number-format:''">
                <span style="font-family:等线; font-size:10.5pt">Your application to participate in beta tests does not
                    uarantee an invitation to, or participate in, any beta test offered by us.</span></li>
        </ul>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">9.Personal data and Security</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Our use of your personal data is subject to the terms and
                conditions set forth in the </span><span style="text-decoration:underline; color:#0000ff">Yuliverse
                Privacy Policy</span><span> . By downloading, installing, accessing, or using the Services, you grant
                your consent to the processing of your personal data in accordance with the {{ compony }} Privacy
                Policy.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We take appropriate measures to prevent unauthorized access
                to, improper use of and the reasonable accuracy of your personal data submitted to us via the Services.
                We use various technologies, including encryption in certain instances, to uphold high security
                standards. Generally, any data stored on our servers is not accessible to the general public. However,
                the usage of internet services inherently carries security risks.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">10.Disclaimer of Warranties</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>NOTWITHSTANDING OUR LIABILITY AS STATED IN SECTION 11 BELOW,
                THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES, EITHER EXPRESS
                OR IMPLIED. SUCH WARRANTIES INCLUDE, BUT ARE NOT LIMITED TO, </span></p>
                <p style="margin-top:0pt; margin-bottom:0pt"><span>MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE. WE DO NOT WARRANT THAT YOU
                WILL BE ABLE TO ACCESS OR USE THE SERVICES AT</span><span style="-aw-import:spaces">&#xa0;
            </span><span>YOUR DESIRED TIMES OR LOCATIONS,THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
                DEFECTS WILL BE CORRECTED, OR THAT THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Please note that some certain jurisdictions may not allow the
                exclusion of certain warranties. Accordingly, some of the above disclaimers may not apply to you.</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">11.Limitation of Liability; Sole and
                Exclusive Remedy; Indemnification</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE
                LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR SIMILAR DAMAGES,
                INCLUDING BUT NOT LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER
                INTANGIBLE LOSSES (REGARDLESS OF HOW SUCH LOSSES ARE QUALIFIED, QUALIFIED OR CATEGORIZED), ARISING FROM
                OR RELATING TO</span><span style="-aw-import:spaces">&#xa0; </span><span>THESE TERMS OR THE SERVICES,
                WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. UNLESS PROHIBITED BY LAW, OUR LIABIITY TO YOU SHALL NOT EXCEED THE AMOUNT
                YOU HAVE PAID TO US IN ACCORDANCE WITH THESE TERMS IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE
                ON WHICH YOU FIRST ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT MADE ANY PAYMENTS TO
                US DURING THAT TIME PERIOD, YOUR SOLE REMEDY (AND OUR EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH US IS TO
                CEASE USING THE SERVICES AND TERMINATE YOUR ACCOUNT.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>NOTHING IN THESE TERMS SHALL AFFECT THE STATUTORY RIGHTS OF
                ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT,
                OR FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD ON OUR PART</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You agree to indemnify, defend and hold {{ compony }}
                (including {{ compony }}’s officers, directors, agents, affiliates, subsidiaries, parent
                companies, joint ventures and employees) harmless from any claim, demand, damages or other losses,
                including reasonable attorneys’ fees, asserted by any third-party arising from or related to your use of
                the Services or any breach of these Terms by you.</span><span style="-aw-import:spaces">&#xa0;
            </span><span>However,</span><span style="-aw-import:spaces">&#xa0; </span><span>this provision is not
                applicable if the infringement of rights is not attributable to your intentional or negligent
                behavior.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">12. Dispute Resolution and
                Applicable Law</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>In the event of a dispute between you and us, we strongly
                recommend that you to first contact our customer support at </span><a href="mailto:support@meta-8.io"
                style="text-decoration:none"><span
                    style="text-decoration:underline; color:#0563c1">support@</span></a><span>yuliverse.com to attempt
                to resolve the matter.. All disputes between you and us shall be governed by the laws of Hong Kong SAR,
                without regard to conflict of law provisions. You agree that any claim or dispute you may have against
                us must be exclusively resolved by a court located in Hong Kong SAR.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>The above paragraph does not limit your statutory rights.
                Consumer protection legislation applicable to you may grant you the right to bring your claim or dispute
                before a different court.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>If you are a resident of the European Union, you may have the
                option to bring your claim or dispute to an Online Dispute Resolution body. Further information on this
                can be found at</span><span>&#xa0;</span><a
                href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show" style="text-decoration:none"><span
                    style="text-decoration:underline; color:#0563c1">https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</span></a><span>.</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">13. Severability</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You and we agree that if any portion of these Terms,
                {{ compony }}’s Privacy Policy or any supplemental/additional terms is deemed illegal or
                unenforceable, in whole or in part, by a court of competent jurisdiction, such provision shall be
                ineffective only in that jurisdiction to the extent of the invalidity or unenforceability, without
                affecting its validity or enforceability in any other jurisdiction or manner. The remaining provisions
                of the Terms shall continue to be valid and enforceable.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">14. General Provisions</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">a. Assignment</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We have the right to assign, transfer or delegate these
                Terms, the {{ compony }} Privacy Policy and any other relevant policies, in whole or in part, to
                any person or entity, at any time, with or without your consent. You may not assign, transfer or
                delegate any rights or obligations under the Terms, the Privacy Policy or other policies without our
                prior written consent.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">15. Supplemental Policies</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We may establish additional or supplementary policies or
                terms related to specific services such as forums, contests or loyalty programs. Your use such services
                are subject to those specific policies and these Terms.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">16. Entire Agreement</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>These Terms, the {{ compony }} Privacy Policy and any
                additional or supplemental policies along with any documents expressly incorporated by reference,
                constitute the entire agreement between you and us. They supersede all prior understandings, whether
                electronic, oral, or written, and whether established by custom, practice, policy, or precedent,
                regarding the subject matter covered by these Terms.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">17. Region-specific terms</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Applying to California residents: California Consumer Privacy
                Act requires requires us to maintain a separate web page that allows you to opt out of the sale of your
                personal information. As we do not track you between services, refer to the individual services you have
                used to learn how to adjust your privacy settings. Please note that we do not sell your personal
                information to third parties without your prior consent (opt-in).</span><span>&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">a. No Waiver</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Our failure to enforce strict performance of any provision of
                these Terms or the {{ compony }} Privacy Policy, or our failure to exercise any right under them,
                shall not be construed as a waiver or relinquishment of our right to assert or rely upon that provision
                or right in any instance. Any express waiver by us of any provision, condition, or requirement of these
                Terms or the {{ compony }} Privacy Policy shall not constitute a waiver of any future obligation
                to comply with such provision, condition or requirement.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Except as expressly and specifically set forth in this these
                Terms, no representations, statements, consents, waivers, or other acts or omissions by us shall be
                deemed a modification of these Terms nor legally binding, unless they are documented in physical writing
                and hand-signed by you and a duly appointed officer of ours. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">b. Notices</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We reserve the right to notify you via the Services or any
                other means of communication using the contact information you provide to us. All notices required from
                or given by you under these Terms or the {{ compony }} Privacy Policy must be in writing and
                addressed to: </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Unit 1404-05, 14/F Nan Fung Towr,</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>88 Connaught Road Central</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Sheung Wan, Hong Kong SAR. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Any notices that you provide without complying with this
                notice provision shall have no legal effect.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">c. Equitable Remedies</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You acknowledge that the rights granted to us and obligations
                imposed on you under these Terms to us are unique and cannot be adequately compensated by monetary
                damages alone. The loss or breach of these rights and obligations would cause irreparable harm to us.
                Therefore, we shall be entitled to seek injunctive or other equitable relief (without the obligation of
                posting any bond, surety, or proof of damages) in the event of any actual or anticipated breach by
                you.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>You irrevocably waive any rights to seek injunctive or other
                equitable relief, or to enjoin or restrain the operation of the Services, the exploitation of any
                advertising or other materials iassociated with it, ot the use or display of the Services, its content
                or any other material used or displayed through the Services. You agree to limit your claims to claims
                for monetary damages as specified in Section 11, if applicable.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">d. Force Majeure</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>We shall not be liable for any delay or failure to perform
                our obligations under these Terms that results from circumstances beyond our reasonable control. Such
                circumstances include, but are not limited to, acts of God, war, terrorism, riots, embargoes, acts of
                civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation
                facilities, fuel, energy, labor, or materials.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Contact information:</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="font-weight:bold">{{ compony }} LIMITED</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Address: Unit 1404-05, 14/F Nan Fung Towr,</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>88 Connaught Road Central</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Sheung Wan, Hong Kong SAR.</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>Email: [</span><a href="mailto:support@meta-8.io"
                style="text-decoration:none"><span
                    style="text-decoration:underline; color:#0563c1">support@</span></a><span>yuliverse.com]</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span>[www.yuliverse.com]</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:0pt; margin-bottom:0pt"><span style="-aw-import:ignore">&#xa0;</span></p>
  </div>
</template>

<script>
import appBridging from "@/utils/appBridging.js";
import { IsMobile } from '@/utils/utils.js';
export default {
  name:'Agreement',
  data(){
    return{
        compony:'METALABS COMPANY'
    }
  },
  created(){
        if(IsMobile){
          if(appBridging.isIOS){
            this.compony = 'CLOUDMATRIX HOLDINGS'
          }else{
            this.compony = 'METALABS COMPANY' 
          }
        }else{
          this.compony = 'METALABS COMPANY'
        }
  },
  methods:{
  }
};
</script>

<style scoped lang="less">
.page{
  padding: 15px 20px 20px 20px;
  text-align: left;
//   background: #F6FAFD;
//   color: #484C58;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  font-weight: 500;
  p {
    line-height: 30px;
  }

  span {
    font-family: 等线;
    font-size: 10.5pt;
    line-height: normal;
    word-break: break-all;
  }
}
@media screen and (min-width: 1000px){
  .page{
        background: rgba(0, 0, 0, 1);        
  }
}
</style>
